import http from '@/units/request'
import axios from 'axios'
export let standardList = null
export let frameworkList = null
/** *
 * 获取标准合同计费列表
 * ***/
export const getstandardList = (data) => {
  if (standardList !== null && data['page.current'] === 1) {
    standardList('取消请求') // 如果上一次的请求还在继续，则取消
  }
  return http({
    url: '/is-agreement/standard/v1/list',
    method: 'get',
    params: data,
    cancelToken: new axios.CancelToken(function (c) {
      standardList = c
    })
  })
}
/** *
 * 获取框架合同计费列表
 * ***/
export const getframeworkList = (data) => {
  if (frameworkList !== null && data['page.current'] === 1) {
    frameworkList('取消请求') // 如果上一次的请求还在继续，则取消
  }
  return http({
    url: 'is-agreement/framework/v1/list',
    method: 'get',
    params: data,
    cancelToken: new axios.CancelToken(function (c) {
      frameworkList = c
    })
  })
}
/** *
 * 获取合同计费详情
 * ***/
export const getAgreementChargingInfo = (agreementChargingId) => {
  return http({
    url: `/is-agreement/charging/v1/info/${agreementChargingId}`,
    method: 'get'
  })
}
/** *
 * 获取合同计费明细表
 * ***/
export const getchargingInfoList = (agreementChargingId) => {
  return http({
    url: `/is-agreement/charging/v1/chargingInfo/${agreementChargingId}`,
    method: 'get'
  })
}
