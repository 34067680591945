<template>
  <div class="standardAddressInfo">
    <!-- 顶部导航 -->
    <commonNavBar :title="'商务合同计费详情'"></commonNavBar>
    <!-- 加载中 -->
    <van-overlay :show="loadingShow">
      <van-loading
        text-color="#0094ff"
        color="#0094ff"
        vertical
      >加载中...</van-loading>
    </van-overlay>
    <div
      class="info"
      v-watermark
    >
      <van-tabs
        v-model="active"
        sticky
        @change="tabChange"
      >
        <van-tab title="最新计费">
          <template #default>
            <div
              class="scrollBox"
              ref="scrollBox"
              @scroll="onScroll"
            >
              <van-collapse
                v-model="activeNames"
                accordion
              >
                <van-collapse-item
                  title="基本信息"
                  name="1"
                >
                  <van-cell
                    center
                    title="合同号:"
                  >
                    <template #default>
                      <div
                        style="color: #1990ff"
                        @click="$router.push({
                          name: 'agreementAddressInfo',
                          params: {
                            id: standardInfo.agreementId,
                            Refresh: true
                          }
                        })"
                        v-if="standardInfo.code && standardInfo.agreementId"
                      >
                        {{ standardInfo.code }}
                      </div>
                      <div v-else>--</div>
                    </template>
                  </van-cell>
                  <van-cell
                    center
                    title="计费单位:"
                    :value="standardInfo.chargingCompanyName || '--'"
                  />
                  <van-cell
                    center
                    title="计费年份:"
                    :value="standardInfo.chargingYear | yearFormat"
                  />
                  <userJump
                    title="更新人:"
                    :userList="standardInfo.updateBy"
                  ></userJump>
                  <van-cell
                    center
                    title="更新日期:"
                    :value="standardInfo.updateDatetime | openYmdFormat"
                  />
                </van-collapse-item>
                <van-collapse-item
                  title="合同计费"
                  name="2"
                >
                  <van-cell
                    center
                    title="计费方式:"
                    :value="standardInfo?.charging?.chargingUnit || '--'"
                  />
                  <van-cell
                    center
                    title="应付年服务费:"
                    :value="standardInfo?.charging?.yearServerCost | moneyFormat
                      "
                  />
                  <van-cell
                    center
                    title="周期服务均价:"
                    :value="standardInfo?.charging?.oneCycleCost | moneyFormat
                      "
                  />
                  <van-cell
                    center
                    title="减免总额:"
                    :value="standardInfo?.charging?.allPreferential | moneyFormat
                      "
                  />
                  <van-cell
                    center
                    title="实付年服务费(含税):"
                    :value="standardInfo?.charging?.allMoneyHasTax | moneyFormat
                      "
                  />
                  <van-cell
                    center
                    title="计费明细:"
                  >
                    <span
                      style="color: #1990ff"
                      @click="chargingInfoHandler(standardInfo.id)"
                      v-if="standardInfo?.charging?.chargingInfo"
                    >{{
                      standardInfo?.charging?.chargingInfo }}条</span>
                    <span v-else>--</span>
                  </van-cell>

                </van-collapse-item>
                <van-collapse-item
                  title="其他信息"
                  name="3"
                >
                  <van-cell
                    center
                    title="补充说明:"
                  >
                    <template #default>
                      <span
                        style="color: #1990ff"
                        @click="
                          clickDialogHandler('补充说明', standardInfo.other)"
                        v-if="standardInfo.other"
                      >点击查看</span>
                      <span v-else>--</span>
                    </template>
                  </van-cell>
                </van-collapse-item>
              </van-collapse>
            </div>
          </template>
        </van-tab>
        <van-tab :title="`历史计费(${standardInfo?.historyCharging?.length || 0})`">
          <template #default>
            <div
              class="scrollBox"
              ref="scrollBoxYyInfo"
              @scroll="onScrollYyInfo"
            >
              <van-cell-group v-if="standardInfo?.historyCharging && standardInfo?.historyCharging?.length">
                <van-cell
                  class="more"
                  :title="item.chargingYear | yearFormat"
                  v-for="(item, index) in standardInfo?.historyCharging"
                  :key="index"
                  @click="$router.push({
                    name: 'historyChargingInfo',
                    params: {
                      id: item.id,
                      Refresh: true
                    },
                    query: {
                      year: item.chargingYear
                    }
                  })"
                >
                  <template #label>
                    <p>计费单位:{{ item.chargingCompanyName || '--' }}</p>
                    <p>计费方式:{{ item.chargingUnit || '--' }}</p>
                    <p>服务费用:{{ item.yearServerCost | moneyFormat }}</p>
                    <p>更新日期:{{ item.updateDatetime | openYmdFormat }}</p>
                  </template>
                  <template #default>
                    <van-tag
                      color="#f59a24"
                      v-if="item.hasChange === 1"
                    >变更</van-tag>
                  </template>
                </van-cell>
              </van-cell-group>
              <div
                class="empty"
                v-else
              >
                <van-empty
                  image="search"
                  description="暂无相关历史计费信息"
                />
              </div>
            </div>
          </template>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>

import { Dialog } from 'vant'
import wx from 'weixin-js-sdk'
import index from '@/mixins'
import { getAgreementChargingInfo } from '@/api/standard'
import dayjs from 'dayjs'

export default {
  name: 'standardAddressInfo',
  mixins: [index],
  data() {
    return {
      activeNames: '1',
      activeIntNames: '1',
      activeOpeNames: '1',
      standardInfo: {},
      loadingShow: false,
      active: 0,
      top: 0,
      topYyInfo: 0,
      fileShow: false,
      fileArr: [],
      fileTitle: ''
    }
  },
  activated() {
    switch (this.active) {
      case 0:
        if (this.$refs.scrollBox) {
          this.$refs.scrollBox.scrollTop = this.top
        }
        break
      case 1:
        if (this.$refs.scrollBoxYyInfo) {
          this.$refs.scrollBoxYyInfo.scrollTop = this.topYyInfo
        }
        break
    }
    if (
      this.$route.params.Refresh
    ) {
      Object.assign(this.$data, this.$options.data.call(this))
      this.getstandardInfo(this.$route.params.id)
    } else {
      if (JSON.stringify(this.standardInfo) === '{}') {
        this.getstandardInfo(this.$route.params.id)
      }
    }
  },
  methods: {
    // 获取详情
    async getstandardInfo(id) {
      this.loadingShow = true
      try {
        const { data } = await getAgreementChargingInfo(id)
        if (data.data) {
          this.standardInfo = data.data
          this.active = 0
          wx.onMenuShareAppMessage({
            title: (this.standardInfo.chargingCompanyName) || '--', // 分享标题
            desc: `合同号:${this.standardInfo.code ? this.standardInfo.code : '--'
              }\n更新人:${this.standardInfo?.updateBy[0]?.name
                ? this.standardInfo?.updateBy[0]?.name
                : '--'
              }\n更新日期:${this.standardInfo.updateDatetime
                ? dayjs(this.standardInfo.updateDatetime).format('YYYY-MM-DD')
                : '--'
              }`, // 分享描述
            link: '', // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
            imgUrl: 'https://is.shfas96119.com/word/FAS.png', // 分享图标
            enableIdTrans: 1, // 是否开启id转译，不填默认为0
            success: function () {
              // 用户确认分享后执行的回调函数
            },
            error: function (res) {
              alert('暂无分享权限')
            },
            cancel: function () {
              // 用户取消分享后执行的回调函数
            }
          })
          this.loadingShow = false
        } else {
          this.loadingShow = false
          if (data?.status === 'E00030007') {
            this.$toast.fail({
              message: '暂无相应权限,请联系管理员!',
              duration: 500,
              onOpened: () => {
                this.$router.replace('/')
              }
            })
            return
          }
          this.$toast.fail({
            message: '当前合同暂无计费!!',
            duration: 1500
          })
        }
      } catch (error) {
        this.loadingShow = false
        if (
          error?.response?.status === 401
        ) {
          return
        }
        if (error?.message?.includes('timeout')) {
          this.$toast.fail({
            message: '网络不好，请稍后再试!',
            duration: 500
          })
          return
        }
        if (error?.response?.data?.status === 'E00030007') {
          this.$toast.fail({
            message: '暂无相应权限,请联系管理员!',
            duration: 500,
            onOpened: () => {
              this.$router.replace('/')
            }
          })
          return
        }
        this.$toast.fail({
          message: '当前网络异常，请稍后再试!!',
          duration: 500
        })
      }
    },
    // 点击查看弹框
    clickDialogHandler(title, text) {
      Dialog.alert({
        title: title,
        message: text,
        confirmButtonColor: '#1989FA'
      }).then(() => {
        // on close
      })
    },
    // 单位基本信息内容滚动
    onScroll(e) {
      this.top = e.target.scrollTop
    },
    // 单位联网信息滚动
    onScrollYyInfo(e) {
      this.topYyInfo = e.target.scrollTop
    },

    // tabs标签切换
    tabChange(name) {
      switch (name) {
        case 0:
          if (this.$refs.scrollBox) {
            this.$nextTick(() => {
              this.$refs.scrollBox.scrollTop = this.top
            })
          }
          break
        case 1:
          if (this.$refs.scrollBoxYyInfo) {
            this.$nextTick(() => {
              this.$refs.scrollBoxYyInfo.scrollTop = this.topYyInfo
            })
          }
          break
      }
    },
    // 计费明细跳转
    chargingInfoHandler(id) {
      this.$router.push(`/workTable/chargingInfoList/${id}`)
    }
  }

}
</script>

<style lang="scss" scoped>
.standardAddressInfo {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;

  ::v-deep {
    .van-overlay {
      .van-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .empty {
      height: 100%;
      position: relative;

      .van-empty {
        position: absolute;
        width: 100%;
        transform: translateY(-50%);
        top: 50%;
      }
    }

    .more {
      .van-cell__title {
        flex: 1;
      }

      .van-cell__value {
        flex: none;
        overflow: hidden;
      }
    }

    .ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    .info {
      height: calc(100% - 46px);
      overflow: hidden;

      .van-tabs__line {
        background-color: #1989fa;
      }

      .van-sticky--fixed {
        top: 46px;
      }

      >.van-tabs {
        height: 100%;

        >.van-tabs__content {
          height: calc(100% - 44px);
          overflow: hidden;

          >.van-tab__pane {
            height: 100%;

            .scrollBox {
              height: 100%;
              overflow: auto;
            }
          }

          .van-collapse {
            background-color: transparent;

            .van-collapse-item {
              background-color: transparent;

              .van-collapse-item__title {
                background-color: white;
                font-weight: 700;
              }

              .van-collapse-item__wrapper {
                background-color: transparent;

                .van-collapse-item__content {
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }
  }

  .changeList {
    .van-cell__title {
      background-color: white;

      >span {
        font-weight: 700;
      }
    }
  }

  .fileDialog {
    padding: 10px;
    padding-bottom: 0;
    max-height: 80%;
    overflow: auto;

    .van-dialog__header {
      text-align: left;
      padding: 0;
    }
  }
}
</style>
